
<template>

    <!-- LOOPING TABLE CASES -->
    <Loading id="loading"/>

    <Modal
      :isVisible="modal.remove_question"
      :promptText="modal.text"
      yesText="Confirm"
      noText="Cancel"
      @yes="confirm_remove(hold.question_key)"
      @no="close_modal()"
    /> 
          <div class="text-center mt-4">
              <h3><WorkflowIcon/> Question</h3>
          </div>
    
    <transition name="fading">
      <div class="overlay" v-if="add_flow">
        <div class="overlay-content">
          <div class="container">
            <div class="card round-card">

              <div class="card-body">
                  <div class="overlay-close"><!-- EXIT BUTTON -->
                    <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="exit_add_flow"></i></div>
                  </div>

                  <div class="card-body">
                    <label class="row mx-1 my-1">Flow Name</label>
                    <input class="form-control mb-2" v-model="new_flow.flowname"></input>

                    <label class="row mx-1 my-1">Flow Specialty</label>
                    <input class="form-control mb-2" v-model="new_flow.specialty"></input>
                  </div>

                  <div class="mx-2">
                    <button class="btn green-btn" @click="submit_add_flow()">Add New Flow</button>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
   </transition> 

    <!-- ================ SYMPTOM EDITOR TABLE ================ -->
    <div class="container platform-size my-1">

      <SearchBar
      searchPlaceholder="Search Flow Question Here.."
      buttonPlaceholder="Search"
      @OutputSearch="get_search_query"
      class="mb-3"
      />

      <div class="card round-card">

          <div class="card-body">

            <div class="flex-md ">
              <div class="row mx-1 mb-2">
                <button class="btn" style="background-color: #0FBAB2; color: white; padding:5px;" @click="add_flow = true"><i class="fa-solid fa-plus"></i> Add Flow Question</button>
              </div>
            </div>

              <Table :input_object="flow_question_data">
                <template v-slot="{ data }">
                    <!-- DESKTOP VIEW LIST -->
                    <div class="row">
                      <div class="col-md desktop-view">
                        <b class="fs-4">{{ data.flowname }}</b> 
                      </div>
                      
                      <div class="col-4 desktop-view absolute-right">
                        <div class="btn-group btn-group-sm" role="group">
                          <button class="btn btn-sm" @click="goto_flow_editor(data.id)"><i class="fa-regular fa-pen-to-square"></i> Questions</button>
                          <button class="btn btn-sm" @click="ask_remove_question(data.id)" style="color:red"><i class="fa-regular fa-trash-can"></i> Delete</button>
                        </div>
                      </div>

                      <div class="desktop-view">
                        <div style="font-size:13px;">● Specialty : {{ data.specialty }}</div>
                      </div>
                    </div>

                    <!-- MOBILE VIEW LIST -->
                    <div class="row">
                        <div class="mobile-view my-0">
                          <b class="fs-4">{{ data.flowname }}</b> 
                        </div>

                      <div class="mobile-view my-0">
                          <div class="btn-group btn-group-md" role="group">
                            <button class="btn btn-sm" @click="goto_flow_editor(data.id)"><i class="fa-regular fa-pen-to-square"></i> Questions</button>
                            <button class="btn btn-sm" @click="ask_remove_question(data.id)" style="color:red"><i class="fa-regular fa-trash-can"></i> Delete</button>
                          </div>
                      </div>

                      <div class="mobile-view my-0">
                        <div style="font-size:13px;">● Specialty : {{ data.specialty }}</div>
                      </div>
                    </div>

                </template>
              </Table>

          </div>

      </div>
    </div>

    





</template>

<script>
import axios from "axios";
import $ from "jquery";
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Table from "@/components/Table.vue";
import URL from "@/pages/URL.json";
import { WorkflowIcon } from 'lucide-vue-next'
const API_URL = URL.api_url;
//PROD https://triage-symptom-db-default-rtdb.asia-southeast1.firebasedatabase.app/
//DEV https://nora-db-default-rtdb.asia-southeast1.firebasedatabase.app/

export default{
components: {
Loading,
Modal,
SearchBar,
Table,
WorkflowIcon
},

created(){
this.get_flow_question_data();
console.warn("Created DOM!");
},
data() {
return {
  raw_flow_data : [],
  flow_question_data : [],
  current_page : 0,
  page_limit : 10,

  add_flow: false,
  new_flow: {
    id: '',
    flowname: '',
    specialty: '',
  },

  search_query: '',

  //CATEGORIES DATA PROCESSING USAGE
  click_categories: false,
  categories_data : [],
  modal :{
    remove_question: false,
    text: '',
  },
  hold :{
    question_key: '',
  }

}
},
computed: {

},
methods: {

get_search_query(search_query){
  // console.log(this.search_query);
  if(search_query){
    this.search_query = search_query;
    
    this.process_question_data(this.raw_flow_data);
    let flowArray = Object.values(this.raw_flow_data);
    let query = this.search_query ? this.search_query.toLowerCase() : ''; // Ensure query is always a string
    
    let filtered_symptoms = flowArray.filter(flow => {
      const matchesFlowname = flow.flowname.toLowerCase().includes(query);
      const matchesSpecialty = flow.specialty.toLowerCase().includes(query);

      // Return true if any of the conditions are met
      return matchesFlowname || matchesSpecialty
    });

    this.process_question_data(filtered_symptoms);
  }
  else{
    this.process_question_data(this.raw_flow_data);
  }

},

ask_remove_question(id){
  this.hold.question_key = id;
  this.modal.remove_question = true;
  this.modal.text = `Are you sure you want to delete ${this.raw_flow_data[id].flowname}`
},

confirm_remove(id){
  delete this.raw_flow_data[id]
  this.process_question_data(this.raw_flow_data);
  this.remove_id(id);
  this.close_modal();
},

async remove_id(id){
    try {
        console.log(`Removing data ${id}...`);
        console.log(id);
        const res = await axios.delete(`${API_URL}/triageflow/${id}.json`);
        console.log(res);
    }
    catch (error) {
          console.error("Error:", error);
    }
},

close_modal(){
  this.hold.question_key = '';
  this.modal.remove_question = false;
  this.modal.text = ``
},

exit_add_flow(){
  this.add_flow = false;
  this.new_flow = [];
},

//FUNCTION TO CREATE A NEW FLOW
async submit_add_flow(){
  console.log(this.new_flow);

  try {
      console.warn("Creating new flow..");
      const response = await axios.post(`${API_URL}/triageflow.json`, this.new_flow);

      //add ID using the KEY created from the post
      let generatedKeyId = response.data.name;
      await axios.patch(`${API_URL}/triageflow/${generatedKeyId}.json`, { id: generatedKeyId });
      
      this.get_flow_question_data();
      this.exit_add_flow();

      console.warn("Done");
  } catch (error) {
        console.error("Error:", error);
  }
},

goto_flow_editor(input_id){
  this.$router.push({ name: 'flow-edit', params: { id: input_id} });
},

async get_flow_question_data(){
  $("#loading").fadeIn(200);

    let api = `${API_URL}/triageflow.json`;
  
    const response = await axios.get(
          api,{headers: {
                "Content-Type": "application/json",
              },
          }
    );

      //console.log(response.data); //debug the data
      if(response.status = 'success'){
        this.raw_flow_data = response.data;
        console.log(this.raw_flow_data);
        this.process_question_data(this.raw_flow_data);
        console.log(this.flow_question_data);

        $("#loading").fadeOut(200);
      }
      else{
        console.error(response);
      }
},

process_question_data(res_data){
    this.flow_question_data = [];
    for (const [key, value] of Object.entries(res_data)) {
      this.flow_question_data.push(value);
    }
},

paginate_list(data){
  let count = 1;
  let index = 0;
  let limit = this.page_limit; //limit data per pages
  let new_data = {};
  let temp_arr = {};

  for (const [key, value] of Object.entries(data)) {
    if(count<limit){
      temp_arr[key] = value;
      new_data[index] = temp_arr
      count++;
    }
    else{
      temp_arr[key] = value;
      new_data[index] = temp_arr,
        
      temp_arr = {}; //reset temp_arr
      index++;
      count = 1;
    }
  }

  //returns example [index of pages] -> [data under limit = 10] 
  console.log(new_data);
  return new_data;

},

count_obj(data){
  let count = 0;
  for (const [key, value] of Object.entries(data)) {
    count++
  }
  return count;
},

}
}
</script>

<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:80% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #ff0000;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.bg-color{
background-color: #0FBAB2
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 12px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.card.hidden{
border: none;
background-color: rgba(255, 255, 255, 0)
}

.absolute-right {
  position: absolute;
  top: 10px;
  right: 10px;
  }
  
.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

</style>

