<template>
    <div class="overlay" id="loading">
        <div class="position-absolute top-50 start-50 translate-middle">
          <img class="fs-1 spinner" style="pointer-events: none; width:100px;" src="@/assets/qmed-icon.png">
        </div>
      </div>
</template>

<style scoped>

.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.spinner {
  animation: spin-animation 1s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>