<template>
    <div class="d-flex justify-content-between align-items-center mb-3">

      <SearchBar
      searchPlaceholder="Search Symptom Question Here.."
      buttonPlaceholder="Search"
      @OutputSearch="get_search_query"
      />

  
      <!-- Dropdown on the Right with Equal Height and Margin Adjusted -->
      <select class="form-control ms-2" style="background-color:#036068; color:white; text-align: center; width:80px;" v-model="selectedCategory" @change="addCategory">
        <option disabled value="">Filter</option>
        <option v-for="category in categories_choice" :key="category" :value="category">{{ Capitalize(category) }}</option>
      </select>

    </div>
  
    <div class="selected-categories">
      <div
        v-for="(category, index) in selectedCategories"
        :key="category"
        class="category-tag"
      >
        {{ Capitalize(category) }}
        <i class="fas fa-times" @click="removeCategory(index)"></i>
      </div>
    </div>
</template>
  

<script setup>
import { ref, defineEmits } from 'vue';
import SearchBar from "@/components/SearchBar.vue";

const components = {
  SearchBar
};

const categories_choice = ref([
  "all",
  "general",
  "skin",
  "head",
  "leg",
  "neck",
  "chest",
  "arms",
  "abdomen",
  "pelvis",
  "back",
  "buttocks",
  "legs",
]);

const selectedCategory = ref('');
const selectedCategories = ref([]);
const searchQuery = ref('');

const emit = defineEmits(['updateCategories', 'searchQueryChanged']);

const addCategory = () => {
  if (selectedCategory.value && !selectedCategories.value.includes(selectedCategory.value)) {
    selectedCategories.value.push(selectedCategory.value);
    emit('updateCategories', selectedCategories.value); // Emit the updated array
  }
  selectedCategory.value = '';
};

const removeCategory = (index) => {
  selectedCategories.value.splice(index, 1);
  emit('updateCategories', selectedCategories.value); // Emit the updated array
};


const Capitalize = (string) =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const get_search_query = (search_query) =>{
  emit('searchQueryChanged', search_query);
}

</script>

<style scoped>
.btn-green{
    background-color: #2cbbb2;
    color: white;
}

.selected-categories {
  margin-top: 10px;
}

.category-tag {
  display: inline-block;
  background-color: #f9effa;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 5px;
}

.category-tag i {
  margin-left: 8px;
  cursor: pointer;
}

/* Adjustments for better spacing and layout */
.d-flex.w-50 {
  width: 50%;
}

.select.w-50 {
  width: 50%;
}

.ms-2 {
  margin-left: 0.5rem;
}
</style>

