
<template>

    <!-- LOOPING TABLE CASES -->
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <AddOrg
    :isActive="isAddOrg"
    @modal-close="close_modal()"
    @modal-submit="submit_modal()"
    />

    <div class="text-center mt-4">
        <h3><BuildingIcon /> Organization</h3>
    </div>

    <!-- ================ SYMPTOM EDITOR TABLE ================ -->
    <div class="container platform-size my-1">

      <SearchBar
      searchPlaceholder="Search Organization Here.."
      buttonPlaceholder="Search"
      @OutputSearch="get_search_query"
      class="mb-3"
      />

      <div class="card round-card">
          <div class="card-body">

            <div class="flex-md ">
              <div class="row mx-1 mb-2">
                <button class="btn" style="background-color: #0FBAB2; color: white; padding:5px;" @click="isAddOrg = true"><i class="fa-solid fa-plus"></i> Add Organization</button>
              </div>
            </div>

                <Table :input_object="render_data">
                    <template v-slot="{ data }">

                        <div class="row">
                            <div class="col-md desktop-view">
                              <b class="fs-4">{{ data.OrganizationName }}</b> 
                            </div>
                            
                            <div class="col-4 desktop-view absolute-right">
                                <div class="btn-group btn-group-sm" role="group">
                                  <button class="btn btn-sm" @click="goto_editor(data.OrganizationUuid)"><i class="fa-regular fa-pen-to-square"></i> Details</button>
                                  <button class="btn btn-sm" @click="ask_remove(data.OrganizationUuid)" style="color:red"><i class="fa-regular fa-trash-can"></i> Delete</button>
                                </div>
                              </div>

                            <div class="desktop-view">
                                <div style="font-size:13px;">● City: {{ data.City }}</div>
                                <div style="font-size:13px;">● State : {{ data.State }}</div>
                                <div style="font-size:13px;">● Postal Code : {{ data.PostalCode }}</div>
                                <div style="font-size:13px;">● Email : {{ data.ContactEmail }}</div>
                                <div style="font-size:13px;">● Contact Phone : {{ data.ContactPhone }}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mobile-view my-0">
                              <b class="fs-4">{{ data.OrganizationName }}</b> 
                            </div>
                            <div class="mobile-view my-0">
                              <div class="btn-group btn-group-md" role="group">
                                <button class="btn btn-sm" @click="goto_editor(data.OrganizationUuid)"><i class="fa-regular fa-pen-to-square"></i> Details</button>
                                <button class="btn btn-sm" @click="ask_remove(data.OrganizationUuid)" style="color:red"><i class="fa-regular fa-trash-can"></i> Delete</button>
                              </div>
                            </div>

                            <div class="mobile-view my-0">
                                <div style="font-size:13px;">● City: {{ data.City }}</div>
                                <div style="font-size:13px;">● State : {{ data.State }}</div>
                                <div style="font-size:13px;">● Postal Code : {{ data.PostalCode }}</div>
                                <div style="font-size:13px;">● Email : {{ data.ContactEmail }}</div>
                                <div style="font-size:13px;">● Contact Phone : {{ data.ContactPhone }}</div>
                            </div>
                        </div>
                    
                    </template>
                </Table>

          </div>

      </div>
    </div>

    





</template>

<script>
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Table from "@/components/Table.vue";
import AddOrg from "@/pages/Organization/components/AddOrganization.vue"
import { getAllOrg } from "@/services/orgServices"
import {BuildingIcon} from 'lucide-vue-next'


export default{
components: {
Loading,
Modal,
SearchBar,
Table,
AddOrg,
BuildingIcon
},

created(){
this.get_data();
console.warn("Created DOM!");
},
data() {
    return {
    raw_data : [],
    render_data : [],
    current_page : 0,
    page_limit : 10,
    isAddOrg: false,
    isLoading: false,
    search_query: '',
    click_categories: false,
    modal :{
        remove_question: false,
        text: '',
    },
    hold :{
        question_key: '',
    }

    }
},
computed: {

},
methods: {

get_search_query(search_query){
  this.render_data = this.raw_data;

  let list = Object.values(this.render_data);
  let query = search_query ? search_query.toLowerCase() : ''; // Ensure query is always a string

    let filtered_data = list.filter(data => {
      const matchesState = data.State.toLowerCase().includes(query);
      const matchesName = data.OrganizationName.toLowerCase().includes(query);
      const matchesCity = data.City.toLowerCase().includes(query);
      const matchesAddress = data.HQAddress.toLowerCase().includes(query);

      // Return true if any of the conditions are met
      return matchesState || matchesName || matchesCity || matchesAddress
    });

    this.render_data = filtered_data;
},

async get_data(){
    this.isLoading = true
    const result = await getAllOrg();

    if(result.data){
        this.isLoading = false
        this.raw_data = result.data;
        this.render_data = result.data; 
    }
},

process_render_data(raw_data){
    this.render_data = [];
    for (const [key, value] of Object.entries(raw_data)) {
      this.render_data.push(value);
    }
},

goto_editor(input_id){
    this.$router.push({ name: 'org-edit', params: { id: input_id} });
},

submit_modal(){
  this.isAddOrg = false;
  this.get_data();
},

close_modal(){
  this.isAddOrg = false;
},

count_obj(data){
  let count = 0;
  for (const [key, value] of Object.entries(data)) {
    count++
  }
  return count;
},

}
}
</script>

<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:100% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #ff0000;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.bg-color{
background-color: #0FBAB2
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 12px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.card.hidden{
border: none;
background-color: rgba(255, 255, 255, 0)
}

.absolute-right {
  position: absolute;
  top: 10px;
  right: 10px;
  }
  
.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

</style>

