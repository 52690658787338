<template>

    <!-- TABLE -->
    <div class="flex-md">
            <transition-group tag="ul" name="fadechange" class="list-group" v-if="input_object.length > 0">
                <li v-for="(data, key, index) in paginate_list(input_object)[current_page]" :key="key" class="list-group-item text-start">
                    <slot :data="data"></slot> 
                </li>
            </transition-group>
            <div v-else class="flex-center">
                <div class="text-center my-2 btn btn-outline-dark pe-none">
                    No Data Available
                </div>
            </div>
      </div>
      
        <!-- NAV PAGINATE FOR COMPLAINT -->
        <nav aria-label="Page navigation">
            <div class="mt-3">
                <ul class="pagination justify-content-center">
                    <!-- PREVIOUS -->
                    <li class="page-item">
                        <a v-if="current_page > 0" class="page-link" aria-label="Next" @click="current_page = current_page - 1">
                            <span aria-hidden="true" style="cursor: pointer; color:#0FBAB2">&laquo;</span>
                        </a>
                        <a v-else class="page-link disabled" aria-label="Next">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
        
                    <!-- PAGE LIST -->
                    <li class="page-item" v-for="(data, key) in paginate_list(input_object)">
                        <a v-if="current_page == key" class="page-link active" @click="current_page = key" style="background-color: #0FBAB2">{{parseInt(key) + 1}}</a>
                        <a v-else class="page-link" @click="current_page = parseInt(key)" style="cursor: pointer; color:#0FBAB2">{{parseInt(key) + 1}}</a>
                    </li>
        
                    <!-- NEXT -->
                    <li class="page-item">
                        <a v-if="current_page < (count_obj(paginate_list(input_object))-1)" class="page-link" aria-label="Next" @click="current_page = current_page + 1">
                            <span aria-hidden="true" style="cursor: pointer; color:#0FBAB2">&raquo;</span>
                        </a>
                        <a v-else class="page-link disabled" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div style="width:70px;" class="mx-auto">
                <select class="form-select form-select-md mx-auto" v-model="page_limit"> 
                    <option disabled>Rows per page</option>
                    <option value=5>5</option>
                    <option value=10>10</option>
                    <option value=15>15</option>
                    <option value=20>20</option>
                    <option value=25>25</option>
                    <option value=30>30</option>
                </select>
            </div>
        </nav>

</template>

<script>


export default{
props: {
    // Define 'message' as a prop to receive data from parent
    input_object: {
        required: true,
    },
},

created(){
    console.warn("Created DOM!");
},
data() {
    return {
        current_page : 0,
        page_limit : 10,
    }
},
computed: {

},
methods: {

paginate_list(data){
  let count = 1;
  let index = 0;
  let limit = this.page_limit; //limit data per pages
  let new_data = {};
  let temp_arr = {};

  for (const [key, value] of Object.entries(data)) {
    if(count<limit){
      temp_arr[key] = value;
      new_data[index] = temp_arr
      count++;
    }
    else{
      temp_arr[key] = value;
      new_data[index] = temp_arr,
        
      temp_arr = {}; //reset temp_arr
      index++;
      count = 1;
    }
  }

  //returns example [index of pages] -> [data under limit = 10] 
  console.log(new_data);
  return new_data;

},

count_obj(data){
  let count = 0;
  for (const [key, value] of Object.entries(data)) {
    count++
  }
  return count;
},

}
}
</script>
<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:100% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #ff0000;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.bg-color{
background-color: #0FBAB2
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 12px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.card.hidden{
border: none;
background-color: rgba(255, 255, 255, 0)
}

.absolute-right {
  position: absolute;
  top: 10px;
  right: 10px;
  }
  
.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.flex-center{
    display: flex;
    justify-content: center; 
    align-items :center;
  }
</style>