<template>
  <div>
    <!-- Conditionally show LeftNavBar for non-login and non-error pages -->
    <LeftNavBar v-if="!isLoginPage && !isErrorPage">
      <router-view></router-view>
    </LeftNavBar>

    <!-- Directly show the routed component for login or error pages -->
    <router-view v-else></router-view>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import LeftNavBar from './components/LeftNavBar.vue';

// Get the current route
const route = useRoute();

// Compute reactive properties to check if the current page is the login or error page
const isLoginPage = computed(() => route.name === 'login');
const isErrorPage = computed(() => route.name === 'error');
</script>