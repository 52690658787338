<template>
    <transition-group name="fading">
      <div class="overlay" v-if="isActive">
        <div class="overlay-content" v-if="loaded">
          <div class="container">
            <div class="card round-card">
  
              <div class="card-body">
                <!-- Centered "Edit Details" text -->
                <div class="fs-3 text-center">Add Clinic</div>
  
                <div class="overlay-close"><!-- EXIT BUTTON -->
                  <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="close_modal"></i></div>
                </div>

                <div class="text-center" style="color:#ff0000" v-if="isEmpty">Incomplete Fields</div>
  
                <div class="card-body">
                    <label class="row mx-1 my-1">Clinic Name</label>
                    <input class="form-control mb-2" v-model="clinic_data.clinic_name" placeholder="Input Clinic Name">

                    <label class="row mx-1 my-1">Address</label>
                    <textarea class="form-control mb-2" v-model="clinic_data.address" placeholder="Input Clinic Address" />

                    <label class="row mx-1 my-1">City</label>
                    <input class="form-control mb-2" v-model="clinic_data.city" placeholder="Input City" >
                    
                    <label class="row mx-1 my-1">State</label>
                    <select class="form-select mb-2" v-model="clinic_data.state">
                      <option value="">Choose Your State</option>
                      <option :value="data" v-for="(data, key) in states">{{ data }}</option>
                    </select>

                    <label class="row mx-1 my-1">Postal Code</label>
                    <input class="form-control mb-2" v-model="clinic_data.postal_code" placeholder="Input Postal Code" >
                </div>
  
                <!-- Centered buttons in a row -->
                <div class="button-row">
                  <button class="btn red-btn mx-1" @click="close_modal()"><i class="fa fa-ban"></i> Cancel</button>
                  <button class="btn green-btn mx-1" @click="submit()"><i class="fa-solid fa-plus"></i> Add</button>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
        <Loading v-else />
      </div>
    </transition-group>
  </template>

<script>
import { registerClinics } from "@/services/clinicServices"
import Loading from "@/components/Loading.vue";
import StatesJSON from "@/assets/states.json"
import CitiesJSON from "@/assets/cities.json"
export default{

components: {
    Loading,
},

props: {
    isActive: {
        type: Boolean,
    }
},

mounted() {
},

data() {return {   
    loaded: true,
    clinic_data: {
        clinic_name: "",
        address: "",
        city: "",
        state: "",
        country: "Malaysia",
        postal_code: "",
        latitude: "",
        longitude: ""
    },
    states : StatesJSON,
    cities : CitiesJSON,
    isEmpty : false,
    }
},
methods: {

close_modal(){
    this.$emit('modal-close');
},

checkEmptyFields(data) {
    const emptyFields = [];
    for (const key in data) {
        if (data[key] === "") {
            emptyFields.push(key);
        }
    }
    return emptyFields.length > 0 ? emptyFields : null;
},

checkEmpty(){
  let field_arr = this.checkEmptyFields(this.clinic_data);
  let result = []

    for (const key in field_arr) {
      if(field_arr[key] != 'latitude' && field_arr[key] != 'longitude'){
        result.push(field_arr[key])
      }
    }

    if(result.length > 0){
      return true
    }
    else{
      return false
    }
}, 

async submit(){
  console.log(this.clinic_data);
  this.isEmpty = this.checkEmpty();

    if(!this.isEmpty ){
      this.loaded = false;
      const response = await registerClinics(this.clinic_data);

      if(response){
        this.loaded = true;
        this.resetData();
      }
      
      this.$emit('modal-submit');
    }
},

resetData(){
  this.clinic_data = {
        clinic_name: "",
        address: "",
        city: "",
        state: "",
        country: "Malaysia",
        postal_code: "",
        latitude: "",
        longitude: ""
    }
},


}

}


</script>

<style scoped>
.overlay-content {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 2rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.card-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #ff0000;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.fading-enter-active,
.fading-leave-active {
  transition: opacity 0.3s;
}

.fading-enter-from,
.fading-leave-to {
  opacity: 0;
}

/* Center the 'Edit Details' text */
.text-center {
  text-align: center;
}

/* Center buttons in a row */
.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>