<template>
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <Modal
      :isVisible="modal.isSubmitting"
      promptText="Are you sure u want to edit this?"
      yesText="Confirm"
      noText="Cancel"
      @yes="submit_edit()"
      @no="close_modal()"
    /> 

    <div class="container platform-size">
        <div class="card hidden mt-4">
                <div class="left-item">
                    <div class="btn green-btn px-3 desktop-view" @click="$router.push('/org')">
                        <i class="fa fa-chevron-left"></i> Back
                    </div>
                    <div class="btn green-btn px-3 mobile-view"  @click="$router.push('/org')">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                </div>

            <div class="text-center">
                <h2>Organization Edit</h2>
                <h5 class="mb-1">{{raw_org_data.OrganizationName}}</h5>
            </div>
        </div>
    </div>

    <div class="container platform-size">
        <div class="card round-card mb-1">
            <div class="card-body mx-3">
                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Organization Name</h6>
                        <input type="text" class="form-control mx-2" :disabled="!isEditing" v-model="org_data.organization_name">
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">HQ Address</h6>
                        <textarea class="form-control mx-2" :disabled="!isEditing" v-model="org_data.hq_address"/>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Contact Email</h6>
                        <input class="form-control mx-2" :disabled="!isEditing" v-model="org_data.contact_email"/>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Contact Phone</h6>
                        <input class="form-control mx-2" :disabled="!isEditing" v-model="org_data.contact_phone"/>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">City</h6>
                        <input type="text" class="form-control mx-2" :disabled="!isEditing" v-model="org_data.city">
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">State</h6>
                            <select class="form-select mx-2" :disabled="!isEditing" v-model="org_data.state">
                                <option value="">Choose Your State</option>
                                <option :value="data" v-for="(data, key) in states">{{ data }}</option>
                            </select>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Postal Code</h6>
                        <div>
                            <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="org_data.postal_code">
                        </div>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Lattitude</h6>
                        <div>
                            <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="org_data.latitude">
                        </div>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Longitude</h6>
                        <div>
                            <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="org_data.longitude">
                        </div>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Subscription Plan</h6>
                        <div>
                            <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="org_data.subscription_plan">
                        </div>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Subscription Type</h6>
                        <div>
                            <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="org_data.subscription_type">
                        </div>
                    </div>

                    <div class="mb-3 mx-3">
                        <h6 class="form-label mx-2 category">Subscription Status</h6>
                        <div>
                            <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="org_data.subscription_status">
                        </div>
                    </div>
                    
            </div>

            <div class="card no-border mx-4">
                <div class="d-flex mx-3 mb-3">
                    <div class="ms-auto">
                        <div class="btn green-btn text-white mx-1" v-if="!isEditing" @click="isEditing = true">
                            <i class="fa fa-pencil-square-o"></i> Edit
                        </div>
                        <div class="btn red-btn text-white mx-1" v-if="isEditing" @click="isEditing = false">
                            <i class="fa fa-ban"></i> Cancel
                        </div>
                        <div class="btn green-btn text-white mx-1" v-if="isEditing" @click="modal.isSubmitting = true; isEditing = false">
                            <i class="fa fa-floppy-o"></i> Submit 
                        </div>
                    </div>
                </div>
           </div>


        </div>
    </div>



</template>

<script>
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import { getCurrentOrg, editOrg } from "@/services/orgServices"
import StatesJSON from "@/assets/states.json"


export default{
components: {
    Loading,
    Modal,
},

created(){
    this.org_id = this.$route.params.id;
    this.get_data(this.org_id);
    console.warn("Created DOM!");
},

data() {return {   
    org_id: '',
    raw_org_data: [],
    org_data: {
        org_name: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        latitude: "",
        longitude: ""
    },
    isLoading: false,
    modal: {
        isSubmitting: false,
    },
    isEditing: false,
    states: StatesJSON
}

},
computed: {
},
methods: {
    async get_data(uuid){
        this.isLoading = true
        const result = await getCurrentOrg(uuid);

        if(result.data){
            this.isLoading = false;
            this.raw_org_data  = result.data;
        }

        console.log(this.raw_org_data);

        this.convert_data(this.raw_org_data);
    },

    convert_data(input_data){
        this.org_data = {
            organization_name: input_data.OrganizationName,
            hq_address: input_data.HQAddress,
            city: input_data.City,
            state: input_data.State,
            country: "Malaysia",
            postal_code: input_data.PostalCode,
            latitude: input_data.Latitude,
            longitude: input_data.Longitude,
            contact_email: input_data.ContactEmail,
            contact_phone: input_data.ContactPhone,
            subscription_plan: input_data.SubscriptionPlan,
            subscription_type: input_data.SubscriptionType,
            subscription_status: input_data.SubscriptionStatus
        },

        console.log(this.org_data);
    },

    close_modal(){
        this.modal.isSubmitting = false;
        this.convert_data(this.raw_org_data);
    },

    async submit_edit(){
        this.isLoading = true;
        this.isEditing = false;
        this.modal.isSubmitting = false;
        console.log(this.org_data);
        const result = await editOrg(this.org_data, this.org_id);
        if(result){
            this.isLoading = false;
        }

        this.get_data(this.org_id);
    }
}
}
</script>

<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:70% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
width:auto;
max-width: 200px;
padding:4px;
margin:4px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 62, 65, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card.hidden{
    border: none;
    background-color: rgba(255, 255, 255, 0)
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #dc362e !important;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.disabled-input{
    background-color: #f9fafe !important;
    border-radius:8px;
}

.bg-color{
background-color: #0FBAB2
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 14px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.absolute-right {
position: absolute;
top: 10px;
right: 10px;
}

.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
}

.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.no-border{
    border: none;
}

.bold-txt{
    font-weight: 550;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
font-size: 2rem;
}
  
.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
font-size: 1.5rem;
cursor: pointer;
white-space: nowrap;
}

.fav{
    background-color:#f9effa;
    color: black;
}


.fav:hover{
    background-color:#8139af;
    color: white;
}

.fav.active{
    background-color:#8139af;
    color: white;
}

.fav.active:hover{
    background-color:#f9effa;
    color: black;
}

</style>