<template>
    <transition-group name="fading">
        <div class="overlay" v-if="isActive">
            <div class="overlay-content" v-if="loaded">
                <div class="container">
                  <div class="card round-card">
      
                    <div class="card-body">
                        <div class="fs-3">Symptom Flow</div>
    
                        <div class="overlay-close"><!-- EXIT BUTTON -->
                          <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="close_modal"></i></div>
                        </div>
      
                        <div class="card-body">
                          <label class="row mx-1 my-1">Flow Question after triage for this Symptom</label>
                            <select class="form-select" v-model="var_data.triageflow">
                                <!-- <option value="">Choose the Result Detail for Flow</option> -->
                                <option v-for="(val, key) in select_list" :key="key" :value="key">{{ val.flowname }}</option>
                            </select>

                        </div>
      
                        <div class="mx-2">
                          <button class="btn red-btn mx-1 w-25" @click="close_modal()"><i class="fa fa-ban"></i> Cancel</button>
                          <button class="btn green-btn mx-1 w-25" @click="submit()"><i class="fa fa-floppy-o"></i> Save</button>
                        </div>
                        
                    </div>
      
                  </div>
                </div>
            </div>
    
              <Loading v-else/>
        </div>
    </transition-group>
</template>

<script>

import axios from "axios";
import Loading from "@/components/Loading.vue";
import URL from "@/pages/URL.json";
const API_URL = URL.api_url;
//PROD https://triage-symptom-db-default-rtdb.asia-southeast1.firebasedatabase.app/
//DEV https://nora-db-default-rtdb.asia-southeast1.firebasedatabase.app/

export default{

components: {
    Loading,
},

props: {
    // Define 'message' as a prop to receive data from parent
    inputID: {
        type: String,
        required: true,
    },
    isActive: {
        type: Boolean,
    }
},

mounted() {
    this.get_current_data();
    this.get_flow_selection()
},

watch: {
    isActive(newVal) {
      if (newVal) {
        this.get_current_data() // Call the function when isActive becomes true
      }
    },
},

data() {return {   
    var_data: [],
    loaded: false,
    select_list: {},
    }
},
methods: {

async get_current_data(){
    this.loaded = false;
    let api = `${API_URL}/symptoms/${this.inputID}.json`;

    const response = await axios.get(
        api,{
        headers: {
            "Content-Type": "application/json",
            },
        }
    );
    if(response.status = 'success'){
        this.var_data = response.data;
        this.loaded = true;
        console.log("DATA")
        console.log(this.var_data);
    }
    else{
        console.error(response);
    }
},

close_modal(){
    this.$emit('close-modal');
},

async submit(){
    this.loaded = false;
    let api = `${API_URL}/symptoms/${this.inputID}.json`;
    console.log(this.var_data);

    try {
          // Make a PATCH instead of POST because POST generate unique ID while PATCH replace request to update synonyms
          console.warn("Updating Question Details..");
          await axios.patch(api,this.var_data);
          this.loaded = true;
          this.get_current_data();

          this.$emit('edit-submit');

          console.warn("Done");
      } catch (error) {
            console.error("Error:", error);
      }
},

async get_flow_selection(){
    let api = `${API_URL}/triageflow.json`;
    console.log("FLOW SELECTION");
    
    const response = await axios.get(
        api,{
        headers: {"Content-Type": "application/json"},
        }
    );

    if(response.status = 'success'){
        this.select_list = response.data;
    }
    else{
        console.error(response);
    }

    console.log(this.select_list);
}


}

}


</script>

<style>
.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}
</style>