<template>
    <div class="fullscreen text-white text-center q-pa-md flex flex-center">
      <div>
        <div style="font-size: 30vh; color:black;">
          404
        </div>
  
        <div class="text-h2" style="opacity:.4; color:black;">
          Oops. Nothing here...
        </div>
      </div>
    </div>
</template>
  