import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://langserve-copilot-dp7nm56cca-as.a.run.app';

export const getUser = async () => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_user/details`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getUser(); // Retry after refreshing
        }
        throw error;
    }
}

export const getAllUser = async () => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/users`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllUser(); // Retry after refreshing
        }
        throw error;
    }
}  

//REGISTER

//     "username": "string",
//     "email": "string",
//     "password": "string",
//     "full_name": "string",
//     "role": "user",
//     "profession": "string",
//     "specialization": "string",
//     "clinic_name": "string",
//     "contact_number": "string",
//     "locations": {},
//     "additional_info": {}

export const registerUser = async (userData) => {
    const authStore = useAuthStore();

    console.log(userData);

    try {
      const response = await axios.post(`${BACKEND_URL}/nora_superadmin/register`, userData, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerUser(userData); // Retry after refreshing
        }
        throw error;
    }
};

//   {
//     "user_uuid": "string",
//     "locations": [
//       "string"
//     ]
//   }

export const updateLocationUser = async (data) => {
    const authStore = useAuthStore();

    try {
      const response = await axios.post(`${BACKEND_URL}/nora_superadmin/update_user_locations`, data, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return updateLocationUser(data); // Retry after refreshing
        }
        throw error;
    }
  };

export const editUser = async (input_data, id) => {
const authStore = useAuthStore();

try {
    const response = await axios.put(`${BACKEND_URL}/nora_superadmin/user/${ id }`, input_data, {
    headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
    },
    });

    return response.data; // Return response data upon successful registration
    } catch (error) {
    if (error.response && error.response.status === 401) {
        // Token might be expired, attempt to refresh
        await authStore.refreshAccessToken();
        return updateLocationUser(input_data, id); // Retry after refreshing
    }
    throw error;
}
};

export const getCurrentUser = async (uuid) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.get(`${BACKEND_URL}/nora_superadmin/user/${uuid}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getCurrentUser(uuid); // Retry after refreshing
        }
        throw error;
    }
};


