
<template>
    <Loading id="loading"/>

    <Modal
      :isVisible="modal.remove_question"
      :promptText="modal.text"
      yesText="Confirm"
      noText="Cancel"
      @yes="remove_question(hold.question_key)"
      @no="close_modal()"
    /> 

    <EditFlowDetail
        :inputFlowID="get_flow_id()"
        :isActive="modal.edit_detail"
        @close-modal="close_detail_modal()"
        @edit-submit="flow_detail_submited()"
    />

    <!-- ================ FLOW EDITOR TABLE ================ -->

    <div class="container platform-size">

        <div class="card hidden mt-4">
            
                <div class="left-item">
                    <div class="btn green-btn px-3 desktop-view" @click="$router.push('/flow')">
                        <i class="fa fa-chevron-left"></i> Back
                    </div>
                    <div class="btn green-btn px-3 mobile-view"  @click="$router.push('/flow')">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                </div>

            <div class="text-center">
                <h5 class="mb-1">Flow Question</h5>
                <h2> {{ qs_data.flowname }} </h2>
                <div class="category w-25 mx-auto">
                    {{ qs_data.specialty }}
                </div>
            </div>

            <div class="right-item">
                <div class="btn green-btn px-3 desktop-view" @click="modal.edit_detail = true">
                    <i class="fa-regular fa-pen-to-square"></i> Details
                </div>
                <div class="btn green-btn px-3 mobile-view"  @click="modal.edit_detail = true">
                    <i class="fa-regular fa-pen-to-square"></i>
                </div>
            </div>
        </div>
        
    </div>

    <div class="container platform-size">
        <div class="mb-1">
            <div class="card-body">
                <div class="row mx-auto w-100">
                    <button class="btn" style="background-color: #0FBAB2; color: white; padding:5px;" @click="add_question()"><i class="fa-solid fa-plus"></i> Add Flow Question</button>
                </div>
            </div>
        </div>
    </div>

    <!-- LOAD QS DATA -->

    <div class="container platform-size" v-for="(val, key, index) in qs_data.questions" :key="key" v-if="qs_data.questions">
        <div class="card round-card mb-1">
            <div class="card-body">

                <!-- QUESTION DIV -->
                <div class="card no-border container mx-1">
                    <div class="card-body">

                        <!-- TITLE BAR -->
                        <div class="row my-1">
                            <div class="row category w-auto px-4 py-1">
                                Question
                            </div>
                            <div class="w-auto btn my-1 mx-1 fav" :class="{ active : check_start_key(key) }" title="Start Question" @click="update_start_key(key)">
                                <a v-if="check_start_key(key)"><i class="fa fa-star"></i></a>
                                <a v-else><i class="fa fa-star-o"></i></a>
                            </div>
                            <div class="absolute-right">
                                <div class="right-item">
                                    <div class="btn mx-1 text-white" title="Edit Question" style="background-color: #0FBAB2;" @click="edit_question(key)" v-if="question_key != key">
                                        <i class="fa-regular fa-pen-to-square"></i>
                                    </div>
                                    <div class="btn mx-1 text-white" title="Save Edit Question" style="background-color: #0FBAB2;" @click="update_data()" v-if="question_key == key">
                                        <i class="fa fa-floppy-o"></i>
                                    </div>
                                    <div class="btn mx-1 text-white" title="Cancel Edit Question" style="background-color: #ff0000;" @click="edit_question('')" v-if="question_key == key">
                                        <i class="fa fa-ban"></i>
                                    </div>
                                </div>    
                            </div>
                        </div>

                        <!-- SHOW CURRENT -->
                        <div class="row my-2" v-if="question_key != key">
                            <div class="row my-1">
                                <input disabled class="form-control bold-txt disabled-input" v-model="val.text" ></input>
                            </div>
                            <div class="row my-1">
                                <div class="row category w-auto px-4 py-1">
                                    Yes
                                </div>
                            </div>

                                <div class="row my-1">
                                    <div class="col-3">Result Type</div>
                                    <div class="col-9 bold-txt disabled-input py-1">{{capital(val.choices.yes.resulttype) ? capital(val.choices.yes.resulttype) : '{empty}'}}</div>
                                </div>
                                <div class="row my-1">
                                    <div class="col-3">Result Detail</div>

                                    <div class="col-9 bold-txt disabled-input py-1" v-if="val.choices.yes.resulttype == 'outcome'">
                                        <a v-if="     val.choices.yes.resultdetail == '1'">Self Care (1)</a>
                                        <a v-else-if="val.choices.yes.resultdetail == '2'">See a Doctor (2)</a>
                                        <a v-else-if="val.choices.yes.resultdetail == '3'">See a Doctor Urgently (3)</a>
                                        <a v-else-if="val.choices.yes.resultdetail == '4'">Emergency Care (4)</a>
                                        <a v-else>{Empty}</a>

                                    </div>

                                    <div class="col-9 bold-txt disabled-input py-1" v-else-if="val.choices.yes.resulttype == 'flow'">
                                        <a v-if="qs_data.questions[val.choices.yes.resultdetail]">
                                            {{val.choices.yes.resultdetail ? qs_data.questions[val.choices.yes.resultdetail].text : '{Empty}'}}
                                        </a>
                                        <a v-else>
                                            {End}
                                        </a>
                                    </div>

                                    <div class="col-9 bold-txt disabled-input py-1" v-else>
                                        <a>{Empty}</a>
                                    </div>
                                </div>

                            <div class="row my-1">
                                <div class="row category w-auto px-4 py-1 my-2">
                                    No
                                </div>
                            </div>

                                    <div class="row my-1">
                                        <div class="col-3">Result Type</div>
                                        <div class="col-9 bold-txt disabled-input py-1">{{capital(val.choices.no.resulttype) ? capital(val.choices.no.resulttype) : '{empty}'}}</div>
                                    </div>
                                    <div class="row my-1">
                                        <div class="col-3">Result Detail</div>

                                        <div class="col-9 bold-txt disabled-input py-1" v-if="val.choices.no.resulttype == 'outcome'">
                                            <a v-if="val.choices.no.resultdetail == '1'">Self Care (1)</a>
                                            <a v-else-if="val.choices.no.resultdetail == '2'">See a Doctor (2)</a>
                                            <a v-else-if="val.choices.no.resultdetail == '3'">See a Doctor Urgently (3)</a>
                                            <a v-else-if="val.choices.no.resultdetail == '4'">Emergency Care (4)</a>
                                            <a v-else>{Empty}</a>

                                        </div>

                                        <div class="col-9 bold-txt disabled-input py-1" v-else-if="val.choices.no.resulttype == 'flow'">
                                            <a v-if="qs_data.questions[val.choices.no.resultdetail]">
                                                {{val.choices.no.resultdetail ? qs_data.questions[val.choices.no.resultdetail].text : '{Empty}'}}
                                            </a>
                                            <a v-else>
                                                {End}
                                            </a>
                                        </div>

                                        <div class="col-9 bold-txt disabled-input py-1" v-else>
                                            <a>{Empty}</a>
                                        </div>
                                    </div>
                                
                        </div>

                       <!-- EDIT -->
                       <div class="row my-2" v-else>

                            <div class="card-disclaimer my-1">
                                <p>The edit will be saved after you click save button</p>
                            </div>

                            <div class="card-shadow round-card my-1 p-4">
                                <label class="row mx-2 my-1 bold-txt">Question Text</label>
                                <input class="form-control mx-1 mb-3"  v-model="val.text" ></input>


                                <!-- ========== YES CHOICE ========== -->
                                <label class="row mx-2 my-1 bold-txt">Yes</label>

                                        <label class="row mx-3 my-1">Result Type</label>
                                        <select class="form-select mb-3 mx-3" v-model="val.choices.yes.resulttype">
                                            <option value="">Choose the Result Type</option>
                                            <option value="outcome">Outcome</option>
                                            <option value="flow">Flow</option>
                                        </select>

                                        <label class="row mx-3 my-1">Result Detail</label>
                                        <!-- OUTCOME -->
                                        <select class="form-select mb-3 mx-3" v-model="val.choices.yes.resultdetail" v-if="val.choices.yes.resulttype == 'outcome'">
                                            <option value="">Choose the Result Detail for Outcome</option>
                                            <option value="1">Self Care (1)</option>
                                            <option value="2">See a Doctor (2)</option>
                                            <option value="3">See a Doctor Urgently (3)</option>
                                            <option value="4">Emergency Care (4)</option>
                                        </select>

                                        <!-- FLOW -->
                                        <select class="form-select mb-3 mx-3" v-model="val.choices.yes.resultdetail" v-else-if="val.choices.yes.resulttype == 'flow'">
                                            <option value="">Choose the Result Detail for Flow</option>
                                            <option v-for="(val_ques, key_ques) in qs_data.questions" :key="key_ques" :value="key_ques">{{ val_ques.text }}</option>
                                        </select>

                                        <div class="form-control mb-3 mx-3 disabled" style="color: red; text-align:left" v-else>
                                            <a>No Result Type Selected Yet</a>
                                        </div>

                                <!-- ========== NO CHOICE ========== -->

                                <label class="row mx-2 my-1 bold-txt">No</label>

                                        <label class="row mx-3 my-1">Result Type</label>
                                        <select class="form-select mb-3 mx-3" v-model="val.choices.no.resulttype">
                                            <option value="">Choose the Result Type</option>
                                            <option value="outcome">Outcome</option>
                                            <option value="flow">Flow</option>
                                        </select>

                                        <label class="row mx-3 my-1">Result Detail</label>
                                        <!-- OUTCOME -->
                                        <select class="form-select mb-3 mx-3" v-model="val.choices.no.resultdetail" v-if="val.choices.no.resulttype == 'outcome'">
                                            <option value="">Choose the Result Detail for Outcome</option>
                                            <option value="1">Self Care (1)</option>
                                            <option value="2">See a Doctor (2)</option>
                                            <option value="3">See a Doctor Urgently (3)</option>
                                            <option value="4">Emergency Care (4)</option>
                                        </select>

                                        <!-- FLOW -->
                                        <select class="form-select mb-3 mx-3" v-model="val.choices.no.resultdetail" v-else-if="val.choices.no.resulttype == 'flow'">
                                            <option value="">Choose the Result Detail for Flow</option>
                                            <option v-for="(val_ques, key_ques) in qs_data.questions" :key="key_ques" :value="key_ques">{{ val_ques.text }}</option>
                                        </select>

                                        <div class="form-control mb-3 mx-3 disabled" style="color: red; text-align:left" v-else>
                                            <a>No Result Type Selected Yet</a>
                                        </div>
                            </div>
                        </div>

                    </div>
                </div>

               <div class="card no-border mx-3">
                    <div class="d-flex mx-3">
                        <div class="ms-auto btn red-btn text-white " @click="ask_remove_question(key)">
                            <i class="fa fa-trash"></i> Question 
                        </div>
                    </div>
               </div>

            </div>
        </div>
    </div>

    <div v-else class="text-center mx-2 my-2 btn btn-outline-dark pe-none">
        No Data Available
    </div>




</template>

<script>
import axios from "axios";
import $ from "jquery";
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import EditFlowDetail from "@/pages/Flow/components/EditFlowDetail.vue";
import URL from "@/pages/URL.json";
const API_URL = URL.api_url;
//PROD https://triage-symptom-db-default-rtdb.asia-southeast1.firebasedatabase.app/
//DEV https://nora-db-default-rtdb.asia-southeast1.firebasedatabase.app/

export default{
components: {
    Loading,
    Modal,
    EditFlowDetail,
},

created(){
    this.get_current_data();
    console.warn("Created DOM!");
},

data() {return {   
    question_id: '',
    qs_data: [],
    choice_key: null,
    question_key: null,
    current_symptom: '',
    hold:{
        question_key: '',
    },
    modal: {
        remove_question: false,
        text: '',
        edit_detail: false,
    }
    
}

},
computed: {
},
methods: {
    
close_modal(){
    this.modal.remove_question = false;
    this.modal.text = '';
    this.hold.question_key = '';
},

remove_question(question_key){
    delete this.qs_data.questions[question_key];
    console.log(this.qs_data.questions);
    this.update_data();
    this.close_modal();
},

ask_remove_question(question_key){
    this.hold.question_key = question_key;
    this.modal.remove_question = true;
    this.modal.text = 'Are you sure you want to remove this Question?';
},

async add_question(){
let payload = {
    choices: {
        yes:{
            resulttype: '',
            resultdetail: '',
        },
        no:{
            resulttype: '',
            resultdetail: '',
        }
    },
    text: "New Question"
  }

  let url = `${API_URL}/triageflow/${this.get_flow_id()}/questions.json`;

  try {
      console.warn("Adding Question..");
      await axios.post(url,payload);

      this.get_current_data();
      window.scrollTo(0, document.body.scrollHeight);

      console.warn("Done");
  } catch (error) {
        console.error("Error:", error);
  }
},

edit_question(key){
    if(key){
        this.question_key = key;
    }
    else if(key == 'reset'){
        this.question_key = null;
    }
    else{
        this.question_key = null;
        this.get_current_data();
    }
},

async update_data(){
    let payload = this.qs_data;

    this.edit_question('reset');
  
    let url = `${API_URL}/triageflow/${this.get_flow_id()}.json`;

    console.log(url);

    try {
          // Make a PATCH instead of POST because POST generate unique ID while PATCH replace request to update synonyms
          console.warn("Updating Question Data..");
          await axios.patch(url,payload);

          this.get_current_data();

          console.warn("Done");
      } catch (error) {
            console.error("Error:", error);
      }

},

get_flow_id() {
    return this.$route.params.id;
},

async get_current_data(){
    $("#loading").fadeIn(200);
    let api = API_URL + `/triageflow/${this.get_flow_id()}.json`;

    const response = await axios.get(
            api,{
            headers: {
                "Content-Type": "application/json",
                },
            }
    );
    if(response.status = 'success'){
        this.qs_data = response.data;

        console.log(this.qs_data);
        $("#loading").fadeOut(200);
    }
    else{
        $("#loading").fadeOut(200);
        console.error(response);
    }
},

check_start_key(id){
    if(this.qs_data.settings){
        if(this.qs_data.settings.start == id){
            return true;
        }
    }
    else{
        return false;
    }
},

update_start_key(id){

    console.log(id);

    if(this.check_start_key(id)){ //if true means user wants to toggle remove it
        this.qs_data.settings = {
            start : ''
        };
    }
    else{
        this.qs_data.settings = {
            start : id
        };
    }

    this.update_data();

    console.log(this.qs_data);
},

check(){
    console.log(this.qs_data);
},

count_obj(data){
  let count = 0;
  for (const [key, value] of Object.entries(data)) {
    count++
  }
  return count;
},

capital(str) {
    // Check if the input string is empty
    if (!str) return str;
    
    // Capitalize the first letter and concatenate it with the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
},

close_detail_modal(){
    this.modal.edit_detail = false;
},

flow_detail_submited(){ //this function is to wait for Edit Details modal to submit then the page will load the latest data upon submission
    this.get_current_data(); 
}

}
}
</script>

<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:100% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 62, 65, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card.hidden{
    border: none;
    background-color: rgba(255, 255, 255, 0)
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #dc362e !important;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.disabled-input{
    background-color: #f9fafe !important;
    border-radius:8px;
}

.bg-color{
background-color: #0FBAB2
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 14px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.absolute-right {
position: absolute;
top: 10px;
right: 10px;
}

.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
}

.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.no-border{
    border: none;
}

.bold-txt{
    font-weight: 550;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
font-size: 2rem;
}
  
.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
font-size: 1.5rem;
cursor: pointer;
white-space: nowrap;
}

.fav{
    background-color:#f9effa;
    color: black;
}


.fav:hover{
    background-color:#8139af;
    color: white;
}

.fav.active{
    background-color:#8139af;
    color: white;
}

.fav.active:hover{
    background-color:#f9effa;
    color: black;
}

</style>

