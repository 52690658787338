<template>
    <transition-group name="fading">
      <div class="overlay" v-if="isActive">
        <div class="overlay-content" v-if="loaded">
          <div class="container">
            <div class="card round-card">
  
              <div class="card-body">
                <!-- Centered "Edit Details" text -->
                <div class="fs-3 text-center">Register User</div>
  
                <div class="overlay-close"><!-- EXIT BUTTON -->
                  <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="close_modal"></i></div>
                </div>

                <div class="text-center" style="color:#ff0000" v-if="isEmpty">Incomplete Fields</div>
  
                <div class="card-body">
                    
                    <label class="row mx-1 my-1">Username</label>
                    <input class="form-control mb-2" v-model="user_data.username" placeholder="Input Username" @input="noSpaces">

                    <label class="row mx-1 my-1">Email</label>
                    <input type="email" class="form-control mb-2" v-model="user_data.email" placeholder="Input Email">

                    <label class="row mx-1 my-1">Password</label>

                    <div class="input-group v">
                        <input type="password" id="password" class="form-control input-form" v-model="user_data.password" placeholder="Input Password">
                        <button class="btn btn-outline-secondary" :class="{'active' : showPassword}" type="button" @click="togglePasswordVisibility">
                            <i class="fas fa-eye" :class="{ 'fa-eye-slash': !showPassword }"></i>
                        </button>
                    </div>

                    <label class="row mx-1 my-1">Full Name</label>
                    <input class="form-control mb-2" v-model="user_data.full_name" placeholder="Input Full Name" >

                    <label class="row mx-1 my-1">Contact Number</label>
                    <input class="form-control mb-2" v-model="user_data.contact_number" placeholder="Input Contact" @input="allowOnlyDigits">
                    
                    <label class="row mx-1 my-1">Role</label>
                    <select class="form-select mb-2" v-model="user_data.role">
                      <option value="">Choose Role</option>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>

                    <label class="row mx-1 my-1">Profession</label>
                    <input class="form-control mb-2" v-model="user_data.profession" placeholder="Input Profession" >

                    <label class="row mx-1 my-1">Clinic Name</label>
                    <input class="form-control mb-2" v-model="user_data.clinic_name" placeholder="Input Clinic Name" >

                    <!-- <label class="row mx-1 my-1">Locations</label>
                    <div class="row mx-1 my-1">
                        <div class=" clinic_list" v-for="(clinic_id, ind) in user_data.locations" :key="ind">
                            <span class="mx-2">{{ clinicsJson[clinic_id]  }}</span>
                            <span class="ms-auto mx-1"><i @click="remove_clinic(ind)" class="fa fa-times-circle-o" style="color:red"></i></span>
                        </div>
                    </div>

                    <select class="form-select" v-model="selectedClinic" >
                        <option value="">Select Locations</option>
                        <option v-for="(data, key) in clinicsSelection" :key="key" :value="data.uuid">
                          {{ data.name }}
                        </option>
                    </select> -->

                    <label class="row mx-1 my-1">Specialization</label>
                    <input class="form-control mb-2" v-model="user_data.specialization" placeholder="Input Specialization" >

                    <label class="row mx-1 my-1">Organization</label>
                    <select class="form-select" v-model="user_data.OrganizationUuid" >
                        <option value="">Select Organization</option>
                        <option v-for="(data, key) in OrganizationList " :key="key" :value="data.uuid">
                          {{ data.name }}
                        </option>
                    </select>

                </div>
  
                <!-- Centered buttons in a row -->
                <div class="button-row">
                  <button class="btn red-btn mx-1" @click="close_modal()"><i class="fa fa-ban"></i> Cancel</button>
                  <button class="btn green-btn mx-1" @click="submit()"><i class="fa-solid fa-plus"></i> Add</button>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
        <Loading v-else />
      </div>
    </transition-group>
  </template>

<script>
import { registerUser } from "@/services/userServices"
import Loading from "@/components/Loading.vue";
import { getAllClinics } from "@/services/clinicServices";
import { getAllOrgArray } from "@/services/orgServices"
export default{

components: {
    Loading,
},

props: {
    isActive: {
        type: Boolean,
    }
},

watch: {
    // Watch for changes to selectedClinic and add the clinic to locations automatically
    selectedClinic(newClinic) {
      if (newClinic && !this.user_data.locations.includes(newClinic)) {
        this.user_data.locations.push(newClinic);

        console.log(this.user_data.locations);
      }
    }
},

mounted() {
    this.get_clinics()
    this.get_orgs();
},

data() {return {   
    loaded: true,
    user_data: {
        username: "",
        email: "",
        password: "",
        full_name: "",
        role: "",
        profession: "",
        specialization: "",
        clinic_name: "",
        contact_number: "",
        locations: [],
        additional_info: {},
        OrganizationUuid: '',
    },
    isEmpty : false,
    selectedClinic: "",
    clinicsJson: [],
    clinicsSelection: [],
    showPassword: false,
    OrganizationList: [],
    }
},
methods: {

close_modal(){
    this.$emit('modal-close');
},

checkEmptyFields(data) {
    const emptyFields = [];
    for (const key in data) {
        if (data[key] === "") {
            emptyFields.push(key);
        }
    }
    return emptyFields.length > 0 ? emptyFields : null;
},

checkEmpty(){
  let field_arr = this.checkEmptyFields(this.user_data);
  let result = []

    for (const key in field_arr) {
      if(field_arr[key] != 'additional_info' && field_arr[key] != 'clinic_name'){
        result.push(field_arr[key])
      }
    }

    if(result.length > 0){
      return true
    }
    else{
      return false
    }
}, 

async submit(){
  console.log(this.user_data);
  this.isEmpty = this.checkEmpty(); 

    if(!this.isEmpty ){
        this.loaded = false;

        console.log(this.user_data);

        const response = await registerUser(this.user_data);

        if(response){
            this.loaded = true;
            this.resetData();
        }
      
      this.$emit('modal-submit');
    }
},

resetData(){
  this.user_data = {
        username: "",
        password: "",
        full_name: "",
        role: "",
        profession: "",
        specialization: "",
        clinic_name: "",
        contact_number: "",
        locations: {},
        additional_info: {},
        OrganizationUuid: '',
        }
},

async get_clinics(){
        const result = await getAllClinics();

        if(result.data){
            for(let i=0; i<result.data.length; i++){
                this.clinicsJson[result.data[i].LocationUuid] = result.data[i].ClinicName;

                let temp = {
                    name: result.data[i].ClinicName,
                    uuid: result.data[i].LocationUuid,
                }

                this.clinicsSelection.push(temp)
            }
        }

    console.log(this.clinicsJson, this.clinicsSelection);
},

async get_orgs(){
    const result = await getAllOrgArray();  
    this.OrganizationList = result;
},

remove_clinic(key){
    this.user_data.locations.splice(key, 1);  // This will remove the item at the index and shift the rest
    console.log(this.user_data.locations);
},

noSpaces(event) {
    this.user_data.username = event.target.value.replace(/\s+/g, '');
},

allowOnlyDigits(event) {
    this.user_data.contact_number = event.target.value.replace(/\D/g, '');  // Remove all non-digit characters
},

togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password');
    if (passwordInput) {
      passwordInput.type = this.showPassword ? 'text' : 'password';
    }
  }

}

}


</script>

<style scoped>
.overlay-content {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 2rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.card-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #ff0000;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.fading-enter-active,
.fading-leave-active {
  transition: opacity 0.3s;
}

.fading-enter-from,
.fading-leave-to {
  opacity: 0;
}

/* Center the 'Edit Details' text */
.text-center {
  text-align: center;
}

/* Center buttons in a row */
.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

div{
    font-size: 12px;
}

.clinic_list{
    border-radius:8px; 
    background-color:#cefffd;
    border-width: 1px;
    width:auto;
    max-width: 600px;
    padding:5px;
    margin:4px;
    text-align:center;
}
</style>