<template>
    <transition-group name="fading">
      <div class="overlay" v-if="isActive">
        <div class="overlay-content" v-if="loaded">
          <div class="container">
            <div class="card round-card">
  
              <div class="card-body">
                <!-- Centered "Edit Details" text -->
                <div class="fs-3 text-center">Edit Details</div>
  
                <div class="overlay-close"><!-- EXIT BUTTON -->
                  <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="close_modal"></i></div>
                </div>
  
                <div class="card-body">
                  <label class="row mx-1 my-1">Flow Name</label>
                  <input class="form-control mb-2" v-model="ques_data.flowname" />
  
                  <label class="row mx-1 my-1">Flow Specialty</label>
                  <input class="form-control mb-2" v-model="ques_data.specialty" />
                </div>
  
                <!-- Centered buttons in a row -->
                <div class="button-row">
                  <button class="btn red-btn mx-1 w-25" @click="close_modal()"><i class="fa fa-ban"></i> Cancel</button>
                  <button class="btn green-btn mx-1 w-25" @click="submit_edit_flow()"><i class="fa fa-floppy-o"></i> Save</button>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
        <Loading v-else />
      </div>
    </transition-group>
  </template>

<script>

import axios from "axios";
import Loading from "@/components/Loading.vue";
import URL from "@/pages/URL.json";
const API_URL = URL.api_url;
//PROD https://triage-symptom-db-default-rtdb.asia-southeast1.firebasedatabase.app/
//DEV https://nora-db-default-rtdb.asia-southeast1.firebasedatabase.app/

export default{

components: {
    Loading,
},

props: {
    // Define 'message' as a prop to receive data from parent
    inputFlowID: {
        type: String,
        required: true,
    },
    isActive: {
        type: Boolean,
    }
},

mounted() {
    this.get_current_data();
},

watch: {
    isActive(newVal) {
      if (newVal) {
        this.get_current_data() // Call the function when isActive becomes true
      }
    },
},

data() {return {   
    ques_data: [],
    loaded: false,
    }
},
methods: {

async get_current_data(){
    this.loaded = false;
    let api = `${API_URL}/triageflow/${this.inputFlowID}.json`;

    const response = await axios.get(
        api,{
        headers: {
            "Content-Type": "application/json",
            },
        }
    );
    if(response.status = 'success'){
        this.ques_data = response.data;
        this.loaded = true;
        console.log(this.ques_data);
    }
    else{
        console.error(response);
    }
},

close_modal(){
    this.$emit('close-modal');
},

async submit_edit_flow(){
    this.loaded = false;
    let api = `${API_URL}/triageflow/${this.inputFlowID}.json`;

    try {
          // Make a PATCH instead of POST because POST generate unique ID while PATCH replace request to update synonyms
          console.warn("Updating Question Details..");
          await axios.patch(api,this.ques_data);
          this.loaded = true;
          this.get_current_data();

          this.$emit('edit-submit');

          console.warn("Done");
      } catch (error) {
            console.error("Error:", error);
      }
}


}

}


</script>

<style scoped>
.overlay-content {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 2rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.card-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}

  .green-btn{
  background-color: #0FBAB2;
  color: white;
  padding:8px;
  }
  
  .red-btn{
  background-color: #ff0000;
  color: white;
  padding:8px;
  }
  
  .white-btn{
  background-color: #ffffff;
  color: black;
  padding:4px;
  }

.fading-enter-active,
.fading-leave-active {
  transition: opacity 0.3s;
}

.fading-enter-from,
.fading-leave-to {
  opacity: 0;
}

/* Center the 'Edit Details' text */
.text-center {
  text-align: center;
}

/* Center buttons in a row */
.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>