import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';
import { getAllClinics } from '@/services/clinicServices';

const BACKEND_URL = 'https://langserve-copilot-dp7nm56cca-as.a.run.app';

export const getAllOrg = async () => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/organizations`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllOrg(); // Retry after refreshing
        }
        throw error;
    }
}
//Get array of UUID and name of organization
export const getAllOrgArray = async () => {
  const authStore = useAuthStore();
  let arrayRes = [];

  try {
      const response = await axios.get(`${BACKEND_URL}/nora_superadmin/organizations`, {
      headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
      },
      });

      for (const [key, value] of Object.entries(response.data.data)) {
        arrayRes.push({
          uuid : value.OrganizationUuid,
          name : value.OrganizationName
        });
      }
  
      return arrayRes;

  } catch (error) {
      if (error.response && error.response.status === 401) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return getAllOrgArray(); // Retry after refreshing
      }
      throw error;
  }
}

export const registerOrg = async (input_data) => {
    const authStore = useAuthStore();
    
    try {
      const response = await axios.post(`${BACKEND_URL}/nora_superadmin/organization`, input_data, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });

      return response.data; // Return response data upon successful registration
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerOrg(); // Retry after refreshing
        }
        throw error;
    }
};

export const editOrg = async (input_data, id) => {
  const authStore = useAuthStore();
  try {
    const response = await axios.put(`${BACKEND_URL}/nora_superadmin/organization/${ id }`, input_data, {
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
      },
    });

    return response.data; // Return response data upon successful registration
  } catch (error) {
      if (error.response && error.response.status === 401) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return editOrg(input_data, id); // Retry after refreshing
      }
      throw error;
  }
};

export const getCurrentOrg = async (location_uuid) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.get(`${BACKEND_URL}/nora_superadmin/organization/${location_uuid}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getCurrentOrg(location_uuid); // Retry after refreshing
        }
        throw error;
    }
};

export const connectOrgWithLocation = async () => {
  const authStore = useAuthStore();

  try {
      let organizations = await getAllOrg();
      organizations = organizations.data;
        const clinicsResponse = await getAllClinics();
        const clinicsData = clinicsResponse.data;

        // Match OrganizationUuid with clinics data and return the desired arrays
        const matchedData = organizations.flatMap(org => 
            clinicsData
                .filter(clinic => clinic.OrganizationUuid === org.OrganizationUuid)
                .map(clinic => ({
                    LocationUuid: clinic.LocationUuid,
                    ClinicName: clinic.ClinicName,
                    OrganizationUuid: org.OrganizationUuid
                }))
        );

        return matchedData;

  } catch (error) {
      if (error.response && error.response.status === 401) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return connectOrgWithLocation(); // Retry after refreshing
      }
      throw error;
  }
}

export const getClinicWithUUID = async (orgs_uuid) => {
  const authStore = useAuthStore();

    try {
        let data = await connectOrgWithLocation();
        let result = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].OrganizationUuid === orgs_uuid) {
                result.push({
                    uuid : data[i].LocationUuid,
                    name : data[i].ClinicName
                });
            }
        }

        console.log("result",result);

        return result;

  } catch (error) {
      if (error.response && error.response.status === 401) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return getClinicWithUUID(orgs_uuid); // Retry after refreshing
      }
      throw error;
  }
}