
<template>

        <!-- LOOPING TABLE CASES -->
        <Loading id="loading"/>

        <Modal
          :isVisible="modal.remove_symptom"
          :promptText="modal.text"
          yesText="Confirm"
          noText="Cancel"
          @yes="remove_symptom(hold.symptom_key)"
          @no="close_modal()"
        /> 
              <div class="text-center mt-4">
                  <h3><PillIcon/> Symptom</h3>
              </div>
        
        <transition name="fading">
          <div class="overlay" v-if="click_new_symptoms">
            <div class="overlay-content">
              <div class="container">
                <div class="card round-card">

                  <div class="card-body">
                      <div class="overlay-close"><!-- EXIT BUTTON -->
                        <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="exit_new_symptom"></i></div>
                      </div>

                      <div class="card-body">
                        <label class="row mx-1 my-1">Symptom Names</label>
                        <input class="form-control mb-2" v-model="new_symptom.name"></input>

                        <label class="row mx-1 my-1">Symptom Categories</label>
                        <div class="form-control mb-2">
                          <div id="synonyms_table">
                            <table>
                                <tr v-for="(category_list, index) in categories_choice" :key="index">
                                  <td style="text-align:left; padding:2px;">
                                    <label :for="category_list">{{ category_list }}</label>
                                  </td>
                                  <td style="color:red; cursor:pointer;">
                                    <input type="checkbox" :id="category_list" :value="category_list" v-model="new_symptom.symptom_category"/>
                                  </td>
                                </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div class="mx-2">
                        <button class="btn green-btn" @click="add_new_symptom()">Add New Symptoms</button>
                      </div>


                  </div>

                </div>
              </div>
            </div>
          </div>
       </transition> 

        <!-- ================ CATEGORIES OVERLAY ================ -->
         <transition name="fading">
            <div class="overlay" v-if="click_categories">
              <div class="overlay-content">
                <div class="container">
                  <div class="card round-card">

                    <div class="card-body">
                        <div class="overlay-close"><!-- EXIT BUTTON -->
                          <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="exit_categories"></i></div>
                        </div>

                        <div class="option-title text-center">
                          <h4>Categories</h4>
                          <p>● {{ symptom_question_data[get_key_from_id(current_symptom_id)].name }} ●</p>
                          <div class="card-disclaimer">
                            <p>Options will be saved on close</p>
                          </div>
                        </div>

                        <div class="card card-shadow m-2 p-3">
                          <div id="synonyms_table">
                            <table>
                                <tr v-for="(category_list, index) in categories_choice" :key="index">
                                  <td style="text-align:left; padding:2px;">
                                    <label :for="category_list">{{ category_list }}</label>
                                  </td>
                                  <td style="color:red; cursor:pointer;">
                                    <input type="checkbox" :id="category_list" :value="category_list" v-model="categories_data" />
                                  </td>
                                </tr>
                            </table>
                          </div>
                        </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
         </transition> 
          
        <!-- ================ SYNONYMS OVERLAY ================ -->
        <transition name="fading">
            <div class="overlay" v-if="click_synonyms">
              <div class="overlay-content">
                <div class="container">
                  <div class="card round-card">

                    <!-- DID NOT CLICK REMOVE SYNONYM-->
                    <div class="card-body" v-if="!click_remove_synonym">
                        <div class="overlay-close"><!-- EXIT BUTTON -->
                          <div class="card-close"><i class="fa-regular fa-circle-xmark" @click="exit_synonyms"></i></div>
                        </div>

                        <div class="option-title text-center">
                          <h4>Synonyms</h4>
                          <p>● {{ symptom_question_data[get_key_from_id(current_symptom_id)].name }} ●</p>
                          <div class="card-disclaimer">
                            <p>Options will be saved on close</p>
                          </div>
                        </div>

                        <div class="card card-shadow m-2 p-3">
                          <div class="flex-center">
                            <div class="btn-group" role="group">
                              <button type="button" 
                              class="btn btn-dark white-btn" 
                              :class="{ active: synonyms_lang === lang }" 
                              v-for="(lang, index) in lang_arr" 
                              @click="synonyms_lang = lang" >{{ lang.toUpperCase() }}</button>
                            </div>
                          </div>
                          
                          <div id="synonyms_table">
                            <transition-group tag="table" name="slide">
                                <tr v-for="(lang_list, index) in synonyms_data[synonyms_lang]" v-bind:key="index" v-if="synonyms_data">
                                  <td style="text-align:left; padding:2px;"> {{ lang_list }}</td>
                                  <td style="color:red; cursor:pointer;"><i class="fa-regular fa-trash-can" @click="confirm_remove_synonym(index, lang_list)"></i></td>
                                </tr>
                            </transition-group>
                          </div>
                        </div>

                        <transition-group tag="div" name="fadechange">
                          <div class="flex-center">
                            <button v-if="!click_add_synonym" class="btn green-btn" @click="click_add_synonym = true"><i class="fa-solid fa-plus"></i> Synonym</button>
                            <div v-else>
                                <input type="text" v-model="new_synonym" class="form-control" placeholder="Enter synonym">
                              
                                <button class="btn m-2 green-btn" @click="add_synonyms"><i class="fa-regular fa-floppy-disk"></i> Save</button>
                                <button class="btn m-2 red-btn" @click="click_add_synonym = false"><i class="fa-solid fa-ban"></i> Cancel</button>
                            </div>
                          </div>
                        </transition-group>
                    </div>

                    <!-- WHEN CLICK REMOVE SYNONYM-->
                    <div class="card-body" v-else>
                      <div class="card card-shadow m-2 p-3 text-center">
                        <div>Are you sure you want to remove</div> 
                        <div style="color: #ff0000">● {{ hold_synonym.name }} ●</div>
                        <button class="btn m-2 green-btn" @click="remove_synonym(hold_synonym.index)"><i class="fa-regular fa-trash-can"></i> Remove</button>
                        <button class="btn m-2 red-btn" @click="click_remove_synonym = false"><i class="fa-solid fa-ban"></i> Cancel</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
        </transition>

        <!-- ================ SYMPTOM EDITOR TABLE ================ -->
        <div class="container platform-size my-1">

          <SympCategory 
          @updateCategories="get_filter_categories" 
          @searchQueryChanged="get_search_query"
          />

          <div class="card round-card">
              <div class="card-body">

                <div class="flex-md ">
                  <div class="row mx-1 mb-2">
                    <button class="btn" style="background-color: #0FBAB2; color: white; padding:5px;" @click="click_new_symptoms = true"><i class="fa-solid fa-plus"></i> Add Symptoms</button>
                  </div>
                </div>

                <Table :input_object="symptom_question_data">
                  <template v-slot="{ data : ques_data}">
                    <div class="row">
                      <div class="col-xl desktop-view">
                        <b class="fs-4">{{ ques_data.name }}</b> 
                        <b class="category" v-for="(category) in ques_data.symptom_category" v-if="ques_data.symptom_category">{{ category }}</b>
                      </div>
                      <div class="col-5 my-auto desktop-view">
                        <div class="btn-group btn-group-sm" role="group">
                          <button class="btn btn-sm" @click="process_synonyms(ques_data.synonyms, ques_data.id)"><i class="fa-solid fa-plus"></i> Synonyms</button>
                          <button class="btn btn-sm" @click="process_categories(ques_data.symptom_category, ques_data.id)"><i class="fa-regular fa-pen-to-square"></i> Categories</button>
                          <button class="btn btn-sm" @click="goto_question_editor(ques_data.id)"><i class="fa-regular fa-pen-to-square"></i> Questions</button>
                          <button class="btn btn-sm" @click="ask_remove_symptom(ques_data.id, ques_data.name)" style="color:red"><i class="fa-regular fa-trash-can"></i> Delete</button>
                        </div>
                      </div>

                      <div class="col-xl mobile-view">
                        <b class="fs-4">{{ ques_data.name }}</b> 
                        <div>
                          <b class="category" v-for="(category) in ques_data.symptom_category" v-if="ques_data.symptom_category">{{ category }}</b>
                        </div>
                      </div>
                      <div class="mobile-view mb-1">
                        <div class="btn-group btn-group-sm" role="group">
                          <button class="btn btn-sm" @click="process_synonyms(ques_data.synonyms, ques_data.id)"><i class="fa-solid fa-plus"></i> Synonyms</button>
                          <button class="btn btn-sm" @click="process_categories(ques_data.symptom_category, ques_data.id)"><i class="fa-regular fa-pen-to-square"></i> Categories</button>
                        </div>
                        <div class="btn-group btn-group-sm" role="group">
                          <button class="btn btn-sm" @click="goto_question_editor(ques_data.id)"><i class="fa-regular fa-pen-to-square"></i> Questions</button>
                          <button class="btn btn-sm" @click="ask_remove_symptom(ques_data.id, ques_data.name)" style="color:red"><i class="fa-regular fa-trash-can"></i> Delete</button>
                        </div>
                      </div>


                    </div>

                    <div v-if="ques_data.triageflow">
                      <div style="font-size:13px;">Triage Flow : {{ ques_data.triageflow }} </div>
                    </div>
          
                    <div v-if="ques_data.synonyms">
                      <div style="font-size:13px;">EN : <a v-for="(synonyms_en) in ques_data.synonyms.en"> ● {{ synonyms_en }} </a> ● </div>
                      <div style="font-size:13px;">MY : <a v-for="(synonyms_my) in ques_data.synonyms.my"> ● {{ synonyms_my }}</a>  ● </div>
                    </div>
                  </template>
                </Table>

              </div>

          </div>
        </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import Table from "@/components/Table.vue";
import { v4 as uuidv4 } from 'uuid';
import SympCategory from "@/pages/Symptom/components/SympCategory.vue";
import URL from "@/pages/URL.json";
import { PillIcon } from 'lucide-vue-next'

const API_URL = URL.api_url;
//PROD https://triage-symptom-db-default-rtdb.asia-southeast1.firebasedatabase.app/
//DEV https://nora-db-default-rtdb.asia-southeast1.firebasedatabase.app/

export default{
  components: {
    Loading,
    Modal,
    SympCategory,
    Table,
    PillIcon
  },

  created(){
    this.get_symptom_question_data();
    console.warn("Created DOM!");
  },
  data() {
    return {
      raw_symptom_data : [],
      symptom_question_data : [],
      symptom_question_page : 0,
      symptom_question_page_limit : 10,
      symptom_data : [],
      translation_data : [],
      current_symptom_id : '',

      //SYNONYMS DATA PROCESSING USAGE
      click_synonyms: false, //initiate synonyms click
      synonyms_data: [], //where synonyms data be put
      synonyms_lang: 'my', //default synonyms lang first appear
      new_synonym: '', //input new synonym
      click_remove_synonym: false, //initiate remove click
      hold_synonym: [], //hold synonyms data for remove
      click_add_synonym: false, // initiate add synonyms
      lang_arr: ['my', 'en'], //array for language

      //CATEGORIES DATA PROCESSING USAGE
      click_categories: false,
      categories_data : [],
      categories_choice: [
            "all",
            "general",
            "skin",
            "head",
            "leg",
            "neck",
            "chest",
            "arms",
            "abdomen",
            "pelvis",
            "back",
            "buttocks",
            "legs",
          ],

          hold: {
            symptom_key: ''
          },
          modal :{
            remove_symptom: false,
            text: '',
          },

          click_new_symptoms: false,
          new_symptom: {
            id: "",
            name: "",
            symptom_category: [],
            synonyms: {
              en: {},
              my: {},
            },
            uid: '',
          },

          filter_category: [],
          search_query: '',

    }
  },
  computed: {

  },
  methods: {

    get_filter_categories(categories) {
      // console.log(this.filter_category);
      if(categories.length>0){
        this.filter_category = categories;
        this.filter_symptom_data();
      }
      else{
        this.process_question_data(this.raw_symptom_data);
      }
    },

    get_search_query(search_query){
      // console.log(this.search_query);
      if(search_query){
        this.search_query = search_query;
        this.filter_symptom_data();
      }
      else{
        this.process_question_data(this.raw_symptom_data);
      }

    },

filter_symptom_data() {

    this.process_question_data(this.raw_symptom_data);
    let symptomsArray = Object.values(this.raw_symptom_data);
    let query = this.search_query ? this.search_query.toLowerCase() : ''; // Ensure query is always a string
    let filter_category = this.filter_category;

    let filtered_symptoms = symptomsArray.filter(symptom => {
      const matchesName = symptom.name.toLowerCase().includes(query);

      const matchesCategoryByQuery = symptom.symptom_category && 
        symptom.symptom_category.some(category => category.toLowerCase().includes(query));

      const matchesFilterCategory = symptom.symptom_category && 
        symptom.symptom_category.some(category => filter_category.includes(category));

      const matchesSynonymsEn = symptom.synonyms?.en?.some(synonym => synonym.toLowerCase().includes(query));

      const matchesSynonymsMy = symptom.synonyms?.my?.some(synonym => synonym.toLowerCase().includes(query));

      // Handle cases where there's no search query
      if (query === '') {
        return matchesFilterCategory;
      }

      // Return true if any of the conditions are met
      return matchesName || matchesCategoryByQuery || matchesFilterCategory || matchesSynonymsEn || matchesSynonymsMy;
    });

    console.log("Filtered Symptoms:");
    console.log(filtered_symptoms);

    this.process_question_data(filtered_symptoms);
},


    //FUNCTION TO CREATE A NEW SYMPTOM LIST
    async add_new_symptom(){
      this.new_symptom.uid = uuidv4();
      //console.log(this.new_symptom);

      try {
          console.warn("Creating new symptom..");
          const response = await axios.post(`${API_URL}/symptoms.json`, this.new_symptom);

          //console.log(response.data.name);
          let generatedKeyId = response.data.name;

          // Update the 'id' field in the new_symptom object
          this.new_symptom.id = generatedKeyId;

          // Update the entry in Firebase with the generated key as the 'id'
          await axios.patch(`${API_URL}/symptoms/${generatedKeyId}.json`, { id: generatedKeyId });
          
          this.add_new_symptom_question(generatedKeyId);
          this.get_symptom_question_data();
          this.exit_new_symptom();

          console.warn("Done");
      } catch (error) {
            console.error("Error:", error);
      }
    },

    //FUNCTION TO CREATE SYMPTOM QUESTION FROM NEW SYMPTOM
    async add_new_symptom_question(new_id){

      let new_data = {
          [new_id] :{
            questions : {},
            settings : {
              start : ""
            }
          }
      }

      // console.log(new_data);
      
        const response = await axios.get(`${API_URL}/symptomquestion.json`,{headers: {"Content-Type": "application/json",},});
        let all_data = response.data;
        
        all_data[new_id] = {
            questions : {},
            settings : {
              start : ""
            }
          }

        const response_after_patch = await axios.patch(`${API_URL}/symptomquestion.json`, all_data);
        //console.log(response_after_patch);
    },

    exit_new_symptom(){
      this.click_new_symptoms = false;
      this.new_symptom = {
            id: "",
            name: "",
            symptom_category: [],
            synonyms: {
              en: {},
              my: {},
            },
            uid: '',
          };
    },

    close_modal(){
      this.modal.remove_symptom = false;
    },

    ask_remove_symptom(key, name){
      this.hold.symptom_key = key;
      this.modal.remove_symptom = true;
      this.modal.text = `Are you sure you want to remove this (${name}) symptom?`
    },

    async remove_symptom(key){
      this.close_modal();
      try {
          console.warn("Removing Symptom..");
            await axios.delete(`${API_URL}/symptoms/${key}.json`);
            this.get_symptom_question_data();
          console.warn("Done");
      } catch (error) {
            console.error("Error:", error);
      }
    },

    //==============================

    goto_question_editor(input_id){
      this.$router.push({ name: 'symptom-edit', params: { id: input_id} });
    },

    async logout(){
      try {
        await signOut(auth);
        this.$router.push("/login");
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },

    async get_symptom_question_data(){
      $("#loading").fadeIn(200);
        let api = API_URL + '/symptoms.json';
      
        const response = await axios.get(
              api,{headers: {
                    "Content-Type": "application/json",
                  },
              }
        );

          //console.log(response.data); //debug the data
          if(response.status = 'success'){
            this.raw_symptom_data = response.data;
            this.process_question_data(this.raw_symptom_data);

            $("#loading").fadeOut(200);
          }
          else{
            console.error(response);
          }
    },

    process_question_data(res_data){
        this.symptom_question_data = [];
        for (const [key, value] of Object.entries(res_data)) {
          this.symptom_question_data.push(value);
        }
    },

    paginate_list(data){
      let count = 1;
      let index = 0;
      let limit = this.symptom_question_page_limit; //limit data per pages
      let new_data = {};
      let temp_arr = {};

      for (const [key, value] of Object.entries(data)) {
        if(count<limit){
          temp_arr[key] = value;
          new_data[index] = temp_arr
          count++;
        }
        else{
          temp_arr[key] = value;
          new_data[index] = temp_arr,
            
          temp_arr = {}; //reset temp_arr
          index++;
          count = 1;
        }
      }

      //returns example [index of pages] -> [data under limit = 10] 
      return new_data;

    },

    count_obj(data){
      let count = 0;
      for (const [key, value] of Object.entries(data)) {
        count++
      }
      return count;
    },

    // ================== SYNONYMS ==================

    process_synonyms(data, id){
      //console.log(data, id);
      this.current_symptom_id = id;
      this.click_synonyms = true;

      if(data){
        this.synonyms_data = data;
      }
      else{ //if empty
        this.synonyms_data = {en: [],my: []}
      }

      this.synonyms_data['symptom_id'] = id;
    },

    add_synonyms() {
      if (this.new_synonym) {
        if(this.synonyms_data[this.synonyms_lang]){ //if already exist just push
          this.synonyms_data[this.synonyms_lang].push(this.new_synonym);
        }
        else{
          this.synonyms_data[this.synonyms_lang] = [this.new_synonym];
        }
        this.new_synonym = '';
        this.click_add_synonym = false;
      }
    },

    confirm_remove_synonym(index, name){
      this.click_remove_synonym = true;
      this.hold_synonym['index'] = index;
      this.hold_synonym['name'] = name;
      //console.log(this.hold_synonym);
    },

    remove_synonym(index){
      console.log("Remove = "+ index);
      if(index == 0){
        this.synonyms_data[this.synonyms_lang].splice(index, index+1);
      }else{
        this.synonyms_data[this.synonyms_lang].splice(index, index);
      }
      this.hold_synonym = [];
      this.click_remove_synonym = false;
    },

    async update_synonym(){
      // console.log(this.synonyms_data);

      let payload = {
        en : this.synonyms_data.en,
        my : this.synonyms_data.my
      }

      try {
          // Make a PATCH instead of POST because POST generate unique ID while PATCH replace request to update synonyms
          console.warn("Updating Synonym..");
          await axios.patch(
            `${API_URL}/symptoms/${this.current_symptom_id}/synonyms.json`,
            payload
          );

          this.get_symptom_question_data();

          console.warn("Done");
      } catch (error) {
            console.error("Error:", error);
      }
    },

    exit_synonyms(){
      this.click_synonyms = false;
      this.update_synonym(); //update / save synonym on exist
    },

    // ================== CATEGORIES ==================

    process_categories(data, id){
      this.current_symptom_id = id;
      this.click_categories = true;
      // console.log(data, id);

      if(data){
        this.categories_data = data;
      }
      else{ //if empty
        this.categories_data = [];
      }

      this.synonyms_data['symptom_id'] = id;
    },

    async update_categories(){
      // console.log(this.categories_data);

      let payload = {
        symptom_category : []
      };

      //seperation
      for (const [key, value] of Object.entries(this.categories_data)) {
        // console.log(key + " " + value);
        if(key != 'symptom_id'){ //because there are key symptom_id so we don't want to put the id in here lol
          payload.symptom_category.push(value);
        }
      }

      try {
          // == payload as {symptom_category = [arrays]}
          console.warn("Updating Categories..");
          await axios.patch(
            `${API_URL}/symptoms/${this.current_symptom_id}.json`,
            payload
          );

          // == update table now realtime ==
          this.symptom_question_data[this.get_key_from_id(this.current_symptom_id)].symptom_category = payload.symptom_category;

          console.warn("Done");
      } catch (error) {
            console.error("Error: ", error);
      }
    },

    exit_categories(){
      this.click_categories = false;
      this.update_categories();
    },

    //function to retrieve key (because symptom_question_id is already itterated)
    get_key_from_id(id){
      // console.log(id);
      for (const [key, value] of Object.entries(this.symptom_question_data)) {
        if(value.id == id){
          return key
        }
      }
    }

  }
}
</script>

<style scoped>

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 1rem;
}

.title {
  text-align: center;
  flex: 1;
}

.pagination-wrapper {
  overflow-x: auto;
  white-space: nowrap;
}

.pagination-wrapper .pagination {
  display: inline-flex;
  flex-wrap: nowrap;
}

.platform-size{
  width:100% !important;
  padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
  display: block;
}
.mobile-view{
  display: none;
}

@media (max-width: 785px) {

  /*show mobile only*/
  .desktop-view{
    display: none;
  }
  .mobile-view{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .platform-size{
    width:100% !important;
    padding:8px !important;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    order: 1;
    margin: 0.5rem 0;
    flex: none;
    align-items: center;
  }

  .logout-button {
    order: 2;
  }
  
}

.overlay-content {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 2rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor:auto;
}

.card-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}

.category{
  border-radius:8px; 
  border-style: solid; 
  border-width: 1px;
  padding:4px;
  margin:4px;
  text-align:center;
}

.border-list{
  border-radius:8px; 
  border-style: solid; 
  border-width: 1px;
  padding:4px;
  margin:4px;
}

.card-shadow {
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.row .card .card-body{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.round-card{
  padding:5px; 
  border-radius:15px;
}

.card-body .card-title{
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
}

div{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.green-btn{
  background-color: #0FBAB2;
  color: white;
  padding:8px;
}

.red-btn{
  background-color: #ff0000;
  color: white;
  padding:8px;
}

.white-btn{
  background-color: #ffffff;
  color: black;
  padding:4px;
}

.bg-color{
  background-color: #0FBAB2
}

.spinner {
  animation: spin-animation 1s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#synonyms_table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#synonyms_table table {
  width: 80%;
}

.fading-enter-active,
.fading-leave-active {
    transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
    opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
  transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
  opacity: 0;
  transform: translateX(-50px);
}
.slide-leave-active{
  position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
  transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
  opacity: 0;
  transform: translateY(-50px);
}
.fadechange-leave-active{
  position:absolute;
}


.option-title h4 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.option-title p {
  margin: 8px 0;
  font-size: 1em;
  color: #666;
}

.card-disclaimer {
  margin-top: -5px;
  margin-bottom: 5px;
  padding: 4px;
  background-color: #fff0d3;
  border-radius: 4px;
  color:red;
  font-size: 12px;
}

.card-disclaimer p {
  margin: 0;
  font-size: 0.875em;
  color: #856404;
}

.card.hidden{
  border: none;
  background-color: rgba(255, 255, 255, 0)
}

.flex-center{
  display: flex;
  justify-content: center; 
  align-items :center;
}


</style>

